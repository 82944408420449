import React from 'react'
import {
    Form,
    Input,
    Select,
    TextArea,
    FormConsumer,
} from '../components/form/form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import {
    CONTACT_US_INTEREST_OPTIONS,
    GETTING_HELP,
    SHARING_A_COMMENT,
} from '../constants/demoRequestsConstants'

const ContactForm = ({ checkInterest, submitContactRequest }) => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const handleReCaptchaVerify = form => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available')
            return
        }

        executeRecaptcha('contact').then(token => {
            submitContactRequest(form, token)
        })
    }

    const interestOptions = CONTACT_US_INTEREST_OPTIONS.map(interest => ({
        label: interest,
        value: interest,
    }))

    const showForm = formState =>
        formState.interest &&
        (formState.interest === GETTING_HELP ||
            formState.interest === SHARING_A_COMMENT)

    return (
        <Form onSubmit={handleReCaptchaVerify} handleChange={checkInterest}>
            <FormConsumer>
                {({ formState }) => (
                    <>
                        <Select
                            required
                            className="contact-select"
                            name="interest"
                            options={interestOptions}
                            placeholder={"I'm interested in..."}
                        />
                        {showForm(formState) && (
                            <>
                                <div className="form-group-2">
                                    <Input
                                        required
                                        name="first_name"
                                        placeholder={'First Name'}
                                    />
                                    <Input
                                        required
                                        name="last_name"
                                        placeholder={'Last Name'}
                                    />
                                </div>
                                <Input
                                    required
                                    name="email"
                                    type="email"
                                    pattern="^([a-zA-Z0-9_\.\-])+@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"
                                    placeholder={'Email'}
                                />
                                <Input
                                    required
                                    name="company"
                                    type="text"
                                    placeholder={'Company'}
                                />
                            </>
                        )}

                        {showForm(formState) && (
                            <>
                                <TextArea
                                    name="message"
                                    placeholder="Message (optional)"
                                />
                                <p className="google-terms">
                                    This site is protected by reCAPTCHA and the
                                    Google{' '}
                                    <a href="https://policies.google.com/privacy">
                                        Privacy Policy
                                    </a>{' '}
                                    and{' '}
                                    <a href="https://policies.google.com/terms">
                                        Terms of Service
                                    </a>{' '}
                                    apply.
                                </p>
                                <button
                                    type="submit"
                                    className="button button-cta"
                                >
                                    Send Email
                                </button>
                            </>
                        )}
                    </>
                )}
            </FormConsumer>
        </Form>
    )
}

export default ContactForm
