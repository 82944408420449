import React, { Component } from 'react'
import { API_TOKEN } from '../constants/globalConstants.js'
import { graphql } from '../../../homespotter/intranet_tools/api/'
import { setAPIToken } from '../../../homespotter/intranet_tools/api/graphql'
import Layout from '../components/Layout'
import OurCompanyNavigationRow from '../components/OurCompanyNavigationRow'
import ViewDemo from '../components/ViewDemo'
import ContactForm from '../components/ContactForm'

import {
    CONTACT_US_INTEREST_CHECKBOXES_MAP,
    CONTACT_US_INTEREST_BUSINESS_MAP,
    GETTING_HELP,
    SHARING_A_COMMENT
} from "../constants/demoRequestsConstants";

import 'isomorphic-fetch'

export default class Contact extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitted: false,
            showDemoForm: false,
            demoFormPreselectedInterest: ''
        }
    }

    submitContactRequest(form, captchaToken) {
        const query = `
            mutation CreateHSContactRequest($input: HsContactRequestInput!) {
                createHsContactRequest(hsContactRequest: $input) {
                email
                }
            }`

        const variables = {
            input: {
                recaptchaToken: captchaToken,
                message: form['message'],
                firstName: form['first_name'],
                lastName: form['last_name'],
                companyName: form['company'],
                email: form['email'],
                reason: form['interest'],
            },
        }

        setAPIToken(API_TOKEN)

        let retry = false
        graphql({ query, variables, retry })
        .then(() => this.setState({ submitted: true }))
        .catch(err => console.log(err))
    }

    checkInterest(formState) {
        let showDemo = formState.interest &&
            formState.interest !== GETTING_HELP &&
            formState.interest !== SHARING_A_COMMENT;

        this.setState({showDemoForm: showDemo, demoFormPreselectedInterest: formState.interest});
    }

    getForm() {
        if (!this.state.submitted) {
            return (
                <>
                    <ContactForm
                        checkInterest={this.checkInterest.bind(this)}
                        submitContactRequest={this.submitContactRequest.bind(this)}
                    />
                    { this.state.showDemoForm &&
                    <ViewDemo
                        openByDefault={true}
                        checked={CONTACT_US_INTEREST_CHECKBOXES_MAP[this.state.demoFormPreselectedInterest]}
                        businessType={CONTACT_US_INTEREST_BUSINESS_MAP[this.state.demoFormPreselectedInterest]}
                        onModalClose={() => {this.checkInterest({showDemo: false})}}
                    />
                    }
                </>
            )
        } else {
            return (
                <p>Thanks! We'll be in touch soon.</p>
            )
        }
    }

    render() {
        return (
            <Layout
                title="Contact Us | HomeSpotter"
                description="Contact info for HomeSpotter. We’d love to hear from you!"
            >
                <OurCompanyNavigationRow location={this.props.location} />
                <div className="ourcompany contact col-12">
                    <header>
                        <h1>How to contact us</h1>
                        <p>
                            We’d love to hear from you! Please give us a bit of
                            information so we know how best to follow up.
                        </p>
                    </header>
                    <div className="container">
                        {  this.getForm() }

                        <div className="address-container">
                            <h5>Our Office</h5>
                            <address>
                                <strong>HomeSpotter</strong>
                                <p>301 4th Ave S</p>
                                <p>Suite 960N</p>
                                <p>Minneapolis, MN 55415</p>
                                <br />
                                <p>612.284.2828</p>
                                <p>info@homespotter.com</p>
                            </address>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
